import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { Flex, Box } from 'reflexbox'
import { css } from '@emotion/core'
import BackgroundImage from 'gatsby-background-image'

import SectionContainer from './SectionContainer'
import Button from './Button'

import { colors, widths, mq } from '../styles/variables'
import { useSalProps } from '../helpers/useSalAnim'
import { getEmSize } from '../styles/mixins'

const StyledContainer = styled(SectionContainer)`
  /* background: ${colors.blueGradient}; */
`
const ServicesContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  margin: 125px auto;
  max-width: ${widths.maxInner}px;
  z-index: 100;
`

const CenteredContent = styled(Box)``

const SectionHeading = styled.h2`
  text-transform: uppercase;
  color: ${colors.white};
  position: relative;
  padding-top: 0.5em;
  margin-bottom: 1em;
  font-size: ${getEmSize(45)}em;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    background: ${colors.white};
    height: 3px;
  }
`

interface SectionProps {
  id?: string
  className?: string
}

const SectionPositions: React.FC<SectionProps> = ({ className, id }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "world_map.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      const salProps = useSalProps()
      return (
        <BackgroundImage
          Tag="section"
          id={id}
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
          css={css`
            &:before {
              animation: 80s scroll infinite linear;
              background-repeat: repeat-x !important;
              height: 100%;
              width: 100%;
            }
          `}
        >
          <StyledContainer>
            <ServicesContainer>
              <CenteredContent p={50} width={[1, 9 / 10, 4 / 5]}>
                <SectionHeading {...salProps}>Is it time to Rumble ?</SectionHeading>
                <p
                  css={css`
                    font-size: ${getEmSize(24)}em;
                    color: #a7a8c7;
                    margin-bottom: 50px;
                    width: '100%';

                    ${mq('md')} {
                      width: '80%';
                    }
                  `}
                  {...salProps}
                  data-sal-delay="200"
                >
                  We are actively building the Rumble community within the Asia Pacific region. If you are looking for challenging project
                  work or would like to become an alliance partner to support joint project ventures we would like to hear from you.
                </p>
                <Button
                  href="https://www.linkedin.com/company/rumble-asia/"
                  primary={false}
                  target="_blank"
                  css={css`
                    background: ${colors.white};
                    color: ${colors.brand};
                    &:hover {
                      border-color: ${colors.white};
                      background: transparent;
                    }
                  `}
                  {...salProps}
                  data-sal-delay="400"
                >
                  Open Positions
                </Button>
              </CenteredContent>
            </ServicesContainer>
          </StyledContainer>
        </BackgroundImage>
      )
    }}
  />
)

export default SectionPositions
