import * as React from 'react'
import styled from '@emotion/styled'
import { mq } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 600px;
  display: flex;
  margin-bottom: 10px;

  h2 {
    font-size: ${getEmSize(35)}em;
    ${mq('md')} {
      font-size: ${getEmSize(45)}em;
    }
  }
`

interface ContainerProps {
  id?: string
  className?: string
}

const SectionContainer: React.FC<ContainerProps> = ({ children, className, id }) => (
  <StyledContainer id={id} className={className}>
    {children}
  </StyledContainer>
)

export default SectionContainer
