import * as React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from 'reflexbox'

import ParallaxBgServices from './ParallaxBgServices'
import Button from './Button'

import { colors } from '../styles/variables'
import { getEmSize } from '../styles/mixins'
import { useSalProps } from '../helpers/useSalAnim'

const StyledContainer = styled.div`
  background: ${colors.blueGradient};
  min-height: 800px;
  overflow: hidden;
`
const ContactContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  justify-content: center;
`

const SectionHeading = styled.h2`
  text-transform: uppercase;
  text-align: center;
  color: ${colors.black};
  position: relative;
  padding-top: 0.5em;
  margin-bottom: 50px;
  font-size: ${getEmSize(45)}em;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    width: 80px;
    background: ${colors.black};
    height: 3px;
  }
`

const CenteredContent = styled(Box)`
  margin: 200px 0 100px;
  text-align: center;
  align-self: center;
`

const Intro = styled.p`
  font-size: ${getEmSize(24)}em;
  color: ${colors.brand};
  text-align: center;
  margin-bottom: 50px;
  padding: 0 20px;
`

interface SectionProps {
  id?: string
  className?: string
}

const SectionContact: React.FC<SectionProps> = ({ className, id }) => {
  const salProps = useSalProps()
  return (
    <StyledContainer id={id} className={className}>
      <ParallaxBgServices
        section="contact"
        css={{
          opacity: '0.4'
        }}
      />
      <ContactContainer>
        <CenteredContent p={50} width={[1, 4 / 5, 1 / 2]}>
          <SectionHeading {...salProps}>Contact</SectionHeading>
          <Intro {...salProps} data-sal-delay="200">
            To find out more about how we can help you, contact our team via the link below
          </Intro>
          <Button href="mailto:hello@rumbleasia.com.au" primary={true} {...salProps} data-sal-delay="200">
            Email us
          </Button>
        </CenteredContent>
      </ContactContainer>
    </StyledContainer>
  )
}

export default SectionContact
