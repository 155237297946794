// Hook
// import { useState, useEffect } from 'react'

// const iOSversion = () => {
//   let iOSMajor = null
//   const isClient = typeof window === 'object'
//   if (isClient) {
//     if (/iP(hone|od|ad)/.test(window.navigator.platform)) {
//       var v = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
//       iOSMajor = parseInt(v[1], 10)
//     }
//   }
//   return iOSMajor
// }

export const useSalProps = () => {
  return {
    'data-sal': 'slide-up',
    'data-sal-ease': 'ease-in'
  }

  const isClient = typeof window === 'object'

  function getProps() {
    const iOSMajor = isClient ? iOSversion() : null
    const disableAnimation = !!(iOSMajor && iOSMajor < 13)
    return disableAnimation
  }

  const [salProps, getWindowNavigator] = useState(getProps())

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleLoad() {
      getWindowNavigator(getProps())
    }

    window.addEventListener('load', handleLoad)
    return () => window.removeEventListener('load', handleLoad)
  }, [])

  return salProps
}
