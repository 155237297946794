import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

import { getEmSize } from '../styles/mixins'
import { colors, widths, mq } from '../styles/variables'

import LinkedInSvg from '../images/icons/linkedin.svg'

export interface ICardProps {
  name: string
  position: string
  image: string
  linkedIn: string
  [x: string]: any
}

const CardContainer = styled.div`
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 310px;
  height: 285px;
  padding: 0;
  transition: all 0.3s ease-out;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  _:-ms-fullscreen {
    justify-content: flex-end;
  }

  &:after {
    content: ' ';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    opacity: 0.45;
    position: absolute;
    z-index: -;
    pointer-events: none;
    display: block;
  }
`
const CardHeading = styled.h3`
  color: ${colors.white};
  text-align: left;
  position: relative;
  margin-bottom: 0;
  font-size: ${getEmSize(22)}em;
`
const ImgContainer = styled(BackgroundImage)`
  display: flex;
  border-radius: 5px;
  margin: 8px;
  overflow: hidden;
`

const CardTeam: React.FC<ICardProps> = ({ name, position, image, linkedIn, ...props }) => (
  <ImgContainer fluid={image}>
    <CardContainer {...props}>
      <a href={linkedIn} target="_blank" css={{ alignSelf: 'flex-end', padding: 20, zIndex: 20 }}>
        <LinkedInSvg
          css={css`
          .fill-color {
            transition: fill 200ms ease-out;
          }
          .stroke-color {
            transition: stroke 200ms ease-out;
          }
        &:hover {
          .fill-color {
            transition: fill 200ms ease-out;
            fill: ${colors.brand};
          }
          .stroke-color {
            transition: stroke 200ms ease-out;
            stroke: ${colors.white};
          }
          .circle-bg {
            transition: fill 200ms ease-out;
            fill: ${colors.white};
        }
          `}
        />
      </a>
      <div
        css={css`
          margin-left: 20px;
          z-index: 10;
          position: absolute;
          bottom: 0;
        `}
      >
        <CardHeading>{name}</CardHeading>
        <p
          css={{
            fontSize: `${getEmSize(16)}em`,
            paddingRight: '30px',
            textAlign: 'left',
            color: colors.gray.calm
          }}
        >
          {position}
        </p>
      </div>
    </CardContainer>
  </ImgContainer>
)

export default CardTeam
