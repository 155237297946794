interface ICloseIconProps {
  fillColor?: string
}
const CloseIcon = ({ fillColor = 'white' }: ICloseIconProps) => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="27" cy="27" r="25" stroke={fillColor} stroke-width="2.5" />
      <path d="M36.2495 18.3008L18.3008 36.2495" stroke={fillColor} stroke-width="2.5" stroke-linecap="round" />
      <path d="M18.3013 18.3008L36.25 36.2495" stroke={fillColor} stroke-width="2.5" stroke-linecap="round" />
    </svg>
  )
}

export default CloseIcon
