import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Flex, Box } from 'reflexbox'

import { mq, widths, colors } from '../styles/variables'

const StyledFooter = styled(Flex)`
  width: 100%;
  background: transparent;
  position: absolute;
  bottom: 0;
  padding: 20px 30px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: ${widths.maxInner}px;
  color: ${colors.brand};
  font-size: 14px;
  display: flex;
  justify-content: center;

  ${mq('sm')} {
    padding: 20px 50px;
  }
`
interface FooterProps {
  copyright: string
}

const Header: React.FC<FooterProps> = ({ copyright }) => (
  <StyledFooter flexWrap="wrap">
    <Box
      width={[1, 1, 1 / 2]}
      p={10}
      css={css`
        text-align: center;
        ${mq('md')} {
          text-align: left;
        }
      `}
    >
      <span css={css``}>© {new Date().getFullYear()} Rumble All Rights Reserved</span>
    </Box>
    <Box width={[1, 1, 1 / 2]} p={10}>
      <ul
        css={css`
          list-style: none;
          display: flex;
          justify-content: center;
          padding-left: 0;

          ${mq('md')} {
            justify-content: flex-end;
          }

          li {
            margin: 0 15px 0 10px;

            a:hover {
              text-decoration: underline;
            }
          }
        `}
      >
        <li>
          <a href="/privacy/" target="_blank">
            Privacy Policy
          </a>
        </li>
        <li
          css={css`
            list-style: disc;
          `}
        >
          <a href="/terms/" target="_blank">
            Terms of Use
          </a>
        </li>
      </ul>
    </Box>
  </StyledFooter>
)

export default Header
