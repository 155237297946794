import * as React from 'react'
import styled from '@emotion/styled'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { colors, mq } from '../styles/variables'

const StyledAnchorLink = styled(AnchorLink)<ButtonProps>`
  position: relative;
  min-width: 14em;
  text-align: center;
  padding: 15px 45px;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 2px solid ${colors.brand};
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 700;
  white-space: nowrap;
  color: ${props => (props.primary ? colors.white : colors.brand)};
  background-color: ${props => (props.primary ? colors.brand : 'transparent')};

  display: flex;
  justify-content: center;

  ${mq('sm')} {
    display: inline-block;
    margin-right: 20px;
  }

  &:hover,
  &:focus {
    color: ${props => (props.primary ? colors.brand : colors.white)};
    background-color: ${props => (props.primary ? 'transparent' : colors.brand)};
    transition: 0.25s;
  }
`
const StyledLink = styled.a<ButtonProps>`
  position: relative;
  min-width: 14em;
  text-align: center;
  padding: 15px 45px;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 2px solid ${colors.brand};
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 700;
  white-space: nowrap;
  color: ${props => (props.primary ? colors.white : colors.brand)};
  background-color: ${props => (props.primary ? colors.brand : 'transparent')};

  display: flex;
  justify-content: center;

  ${mq('sm')} {
    display: inline-block;
    margin-right: 20px;
  }

  &:hover,
  &:focus {
    color: ${props => (props.primary ? colors.brand : colors.white)};
    background-color: ${props => (props.primary ? 'transparent' : colors.brand)};
    transition: 0.25s;
  }
`

interface ButtonProps {
  className?: string
  href?: string
  primary?: boolean
  anchor?: boolean
  [x: string]: any
}

const Button: React.FC<ButtonProps> = ({ children, className, href, primary, anchor, ...props }) =>
  anchor ? (
    <StyledAnchorLink className={className} href={href} primary={primary ? 1 : 0} {...props}>
      {children}
    </StyledAnchorLink>
  ) : (
    <StyledLink className={className} href={href} primary={primary ? 1 : 0} {...props}>
      {children}
    </StyledLink>
  )

export default Button
