import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import { Flex, Box } from 'reflexbox'

import Button from './Button'

import { colors, mq, widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'
import { useSalProps } from '../helpers/useSalAnim'

export const BannerContainer = styled(Flex)`
  width: 100%;
  min-height: 800px;
  align-items: center;
  margin: 0 auto 10px;
  max-width: ${widths.maxInner}px;
  justify-content: center;

  ${mq('sm')} {
    min-height: 100vh;
  }
`

export const CenteredContent = styled(Box)`
  margin: 150px 0 100px;
  ${mq('sm')} {
    margin: 200px 0 100px;
  }
`

interface HeroBannerProps {
  id?: string
  className?: string
}

const HeroBanner: React.FC<HeroBannerProps> = ({ id, className }) => {
  const salProps = {
    'data-sal': 'slide-up'
    // 'data-sal-ease': 'ease-in'
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "hero_banner.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <BackgroundImage Tag="section" id={id} className={className} fluid={imageData} backgroundColor={`#040e18`}>
            <BannerContainer>
              <CenteredContent p={50} width={[1, 9 / 10, 4 / 5]}>
                <h1
                  css={{
                    transition: 'all 200ms ease-in',
                    color: colors.brand,
                    marginBottom: '30px',
                    fontSize: `${getEmSize(40)}em`,
                    [mq('md')]: {
                      fontSize: `${getEmSize(50)}em`
                    },
                    [mq('lg')]: {
                      fontSize: `${getEmSize(60)}em`
                    },
                    [mq('xl')]: {
                      fontSize: `${getEmSize(80)}em`
                    },
                    [mq('xxl')]: {
                      fontSize: `${getEmSize(100)}em`
                    }
                  }}
                  {...salProps}
                >
                  Strategic Solutions,
                  <br />
                  Trusted Outcomes
                </h1>
                <p
                  css={{
                    transition: 'all 200ms ease-in',
                    color: colors.brand,
                    fontSize: `${getEmSize(22)}em`,
                    marginBottom: '80px',
                    [mq('md')]: {
                      fontSize: `${getEmSize(25)}em`
                    },
                    [mq('lg')]: {
                      fontSize: `${getEmSize(28)}em`
                    },
                    [mq('xl')]: {
                      fontSize: `${getEmSize(30)}em`
                    }
                  }}
                  data-sal-delay="200"
                  {...salProps}
                >
                  Rumble provides direct access to the industry’s best minds, relevant experience, and diverse global capability, to provide
                  you with a genuinely unique service that is both personalised and world-class.
                </p>
                <Button href="#services" anchor={true} primary={true} {...salProps}>
                  Our Services
                </Button>
                <Button href="#contact" anchor={true} {...salProps}>
                  Contact Us
                </Button>
              </CenteredContent>
            </BannerContainer>
          </BackgroundImage>
        )
      }}
    />
  )
}

export default HeroBanner
