import * as React from 'react'
import styled from '@emotion/styled'
import { Parallax } from 'react-scroll-parallax'
import { mq } from '../styles/variables'

import Shape1 from '../images/shapes/Service_shape1.svg'
import Shape2 from '../images/shapes/Service_shape2.svg'
import Shape3 from '../images/shapes/Service_shape3.svg'
import Shape4 from '../images/shapes/Service_shape4.svg'
import Shape5 from '../images/shapes/Service_shape5.svg'
import Shape6 from '../images/shapes/Service_shape6.svg'
import Shape7 from '../images/shapes/Service_shape7.svg'
import Shape8 from '../images/shapes/Service_shape8.svg'
import Shape9 from '../images/shapes/Service_shape9.svg'
import Shape10 from '../images/shapes/Service_shape10.svg'
import ArmyArrow from '../images/shapes/Army_arrow.svg'
import BoxShape from '../images/shapes/BoxShape.svg'

const ParallaxContainer = styled.div`
  position: relative;
  z-index: 0;

  .shape {
    position: absolute;
  }
`

const Cluster1 = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  margin: 100px auto 0;
  width: 600px;
  ${mq('xl')} {
    left: auto;
    right: 0;
  }
  ${mq('xxl')} {
    left: auto;
    right: 10%;
  }

  .parallax-outer {
    position: absolute;
  }

  .shape-1 {
    left: 100px;
  }
  .shape-2 {
    top: 250px;
    right: 0;
  }
  .shape-3 {
    top: 20px;
  }
  .shape-4 {
    top: 100px;
    left: -50px;
  }
  .shape-5 {
    top: 300px;
    left: 120px;
  }
`

const Cluster2 = styled.div`
  position: absolute;
  left: 5%;
  margin-top: 300px;
  width: 600px;
  display: none;

  ${mq('xl')} {
    display: block;
  }

  .parallax-outer {
    position: absolute;
  }

  .shape-6 {
    top: 200px;
    left: 100px;
  }
  .shape-7 {
    top: 100px;
    right: 300px;
  }
  .shape-7-contact {
    top: 100px;
    left: 150px;
  }
  .shape-8 {
    top: 30px;
    left: -10px;
  }
  .shape-9 {
    top: 300px;
    left: 20px;
  }
  .army-arrow {
    top: 100px;
    left: 0;
  }
  .box-shape {
    top: 600px;
    left: 150px;
  }
  .shape-10 {
    top: 250px;
    left: 50px;
  }
  .shape-10-contact {
    top: 100px;
    left: 50px;
  }
`

interface ParallaxProps {
  section?: string
}

const ParallaxBgServices: React.FC<ParallaxProps> = ({ section, ...props }) => (
  <ParallaxContainer {...props}>
    <Cluster1>
      <Parallax className="shape-1" y={[-200, 100]} x={[-10, 10]}>
        <Shape1 />
      </Parallax>
      <Parallax className="shape-2" y={[-150, 150]} x={[-10, 10]}>
        <Shape2 />
      </Parallax>
      <Parallax className="shape-3" y={[30, -30]} x={[-10, 10]}>
        <Shape3 />
      </Parallax>
      <Parallax className="shape-4" y={[50, -50]} x={[-10, 10]}>
        <Shape4 />
      </Parallax>
      <Parallax className="shape-5" y={[-200, 100]} x={[50, -50]}>
        <Shape5 />
      </Parallax>
    </Cluster1>

    {section === 'contact' ? (
      <Cluster2
        css={{
          marginTop: 100
        }}
      >
        <Parallax className="box-shape" y={[-100, 100]} x={[-10, 10]}>
          <BoxShape />
        </Parallax>
        <Parallax className="shape-7-contact" y={[-50, 50]} x={[50, -50]}>
          <Shape7 width="300px" />
        </Parallax>
        <Parallax className="army-arrow" y={[-50, 300]} x={[10, -10]}>
          <ArmyArrow />
        </Parallax>
        <Parallax className="shape-10" y={[-100, 200]} x={[-50, 50]}>
          <Shape10 />
        </Parallax>
      </Cluster2>
    ) : (
      <Cluster2
        css={{
          marginTop: 300
        }}
      >
        <Parallax className="box-shape" y={[-100, 100]} x={[-10, 10]}>
          <Shape6 />
        </Parallax>
        <Parallax className="shape-7" y={[-50, 50]} x={[50, -50]}>
          <Shape7 />
        </Parallax>
        <Parallax className="army-arrow" y={[50, -50]} x={[10, -10]}>
          <Shape8 />
        </Parallax>
        <Parallax className="army-arrow" y={[50, -50]} x={[10, -10]}>
          <Shape9 />
        </Parallax>
        <Parallax className="shape-10-contact" y={[-100, 200]} x={[-50, 50]}>
          <Shape10 />
        </Parallax>
      </Cluster2>
    )}
  </ParallaxContainer>
)

export default ParallaxBgServices
